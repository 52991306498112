:root {
  --primary: #4f6eae;
}

.btn {
  padding: 8px 20px;
  border-radius: 30px;
  outline: none;
  border: none;
  background-color: var(--primary);
  cursor: pointer;
}
.btn--primary {
  background-color: var(--primary);
  color: #eeebf8;
  border: 2px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: var(--primary);
  padding: 8px 20px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
  background: var(--primary);
  color: #dddddd;
  transition: all 0.3s ease-out;
}
