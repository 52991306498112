a.navbar-logo img {
  display: flex;
  width: 80px;
  /* margin: 10px; */
}

.navbar {
  /* background: linear-gradient(
    90deg,
    rgba(221, 221, 221, 0.7) 0%,
    rgba(188, 188, 188, 0.5) 100%
  ); */
  background-color: transparent;
  /* background-color: #bcbcbc; */
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  font-size: 1.2rem;
  position: fixed;
  z-index: 100;
}
.navbar.active {
  background: radial-gradient(
    rgba(238, 235, 248, 1) 0%,
    rgba(188, 188, 188, 1) 100%
  );
  transition: 2s ease-in-out;
}
.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: center;
  align-content: center;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: #4f6eae;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #4f6eae;
  color: #eeebf8;
  border-radius: 5px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #4f6eae;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  a.navbar-logo img {
    position: fixed;
    display: flex;
    flex-shrink: 0.25;
    width: 80px;
    margin: 5px;
    left: 15px;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #4348c7;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #eeebf8;
    height: 80%;
  }

  .nav-links:hover {
    background-color: #1888ff;
    color: #fff;
    border-radius: 0;
    height: 80%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #4f6eae;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}
