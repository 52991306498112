* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: "Work Sans", sans-serif;
}
.principal,
.servicios,
.socios,
.contacto,
.registro,
.login {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.principal {
  background-image: url("../../images/squares.png");
  /* background-position: center; */
  /* background-repeat: repeat; */
  /* background-size: auto; */
  color: #4f6eae;
  font-size: 80px;
}

.servicios {
  background-image: url("../../images/img-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.socios {
  background-image: url("../../images/img-3.jpg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contacto {
  background-image: url("../../images/img-4.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.registro {
  background-image: url("../../images/img-7.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.login {
  background-image: url("../../images/img-6.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
